.icono_editar_campo{
    color:blue;
    cursor: pointer;
}

.icono_editar_campo:hover{
    color:rgb(0, 247, 255);
    cursor: pointer;
}

.icono_agregar_permido{
    background-color: rgb(16, 181, 231);
    color:white;
    cursor: pointer;
}