.nombre_seccion{
    font-size:20px;
}

.item_lista_consulta_guardada{
    cursor: pointer;
}

.item_lista_consulta_guardada:hover{
    cursor: pointer;
    color: cyan;
}